import { Helmet, HelmetProvider } from "react-helmet-async"
import NavBar from "../../Components/C0533/NavBar"
import Footer from "../../Components/C0533/Footer"
import { Outlet } from "react-router-dom"

import { useEffect } from "react"

let version = "1.0.2"

const MainLayout = () => {

    useEffect(() => (
        console.log("Version : " + version)
    ), [])

    const content = (
        <>
            <body>
                <HelmetProvider>
                    <div>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>香港跳繩精英訓練社</title>
                            <meta name="description" content="香港跳繩精英訓練社" />
                            <meta name="keywords" content="香港跳繩精英訓練社" />
                            <link rel="canonical" href="https://www.hkrsu.com/" />
                        </Helmet>
                        <NavBar />
                        <Outlet />
                        <Footer />
                    </div>
                </HelmetProvider>
            </body>
        </>
    )
    return content
}

export default MainLayout