import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


//======> C0533 Page
import MainLayout from './Layout/C0533/MainLayout'
import HomePageC0533 from './Pages/C0533/HomePage';
import AboutHKRUS from './Pages/C0533/AboutUs/About_hkrsu';
import OurCrewPage from './Pages/C0533/AboutUs/Our_Crew'
import CoursePage from './Pages/C0533/Classes/CoursePage';
import AcesPage from './Pages/C0533/Classes/AcesPage';
import PerformanceteamPage from './Pages/C0533/PerformanceteamPage';
import UnionCupPage from './Pages/C0533/Competitions/UnionCupPage';
import AWADRSInternationPage from './Pages/C0533/Awards/AWADRSInternationPage';
import AWARDSPage from './Pages/C0533/Awards/AWARDSPage';
import SalesPage from './Pages/C0533/SalesPage';
import ContactUsPage from './Pages/C0533/ContactUsPage';
import SkillTestPage from './Pages/C0533/SkillTestPage';


const cookies = new Cookies();


const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <HomePageC0533 /> },
      { path: '/about-hkrsu', element: <AboutHKRUS /> },
      { path: '/Our-Crew', element: <OurCrewPage /> },
      { path: '/course', element: <CoursePage /> },
      { path: '/Aces', element: <AcesPage /> },
      { path: '/Course', element: <CoursePage /> },
      { path: '/performance_team', element: <PerformanceteamPage /> },
      { path: '/Union_Cup', element: <UnionCupPage /> },
      { path: '/AWADRS-International', element: <AWADRSInternationPage /> },
      { path: '/AWARDS', element: <AWARDSPage /> },
      { path: '/about_rope', element: <SalesPage /> },
      { path: '/contact-us', element: <ContactUsPage /> },
      { path: '/Skill_Test', element: <SkillTestPage /> },
    ]
  },
])

const App = () => {

  return (
        <RouterProvider router={router} />
  )
}

// const rootElement = document.getElementById('root')
// ReactDOM.render(<RouterProvider router={router} />, rootElement);


const rootElement = document.getElementById('root')
ReactDOM.render(<App />, rootElement);