import { useEffect, useState } from 'react'
import css from '../../css/C0533/OurCrew.module.css'

import Crew1 from '../../global/C533/Crew1.jpg'
import Crew2 from '../../global/C533/Crew2.jpg'
import Crew3 from '../../global/C533/Crew3.jpg'
import Crew4 from '../../global/C533/Crew4.jpg'

import Crew5 from '../../global/C533/Crew5.jpg'
import Crew6 from '../../global/C533/Crew6.jpg'
import Crew7 from '../../global/C533/Crew7.jpg'
import Crew8 from '../../global/C533/Crew8.jpg'
import Crew9 from '../../global/C533/Crew9.jpg'

import CrewTeam from '../../global/C533/CrewTeam.jpg'
import { IC0553WebsiteImage } from '../../Interface/interface'
import axios from 'axios'

const OurCrew = () => {

    const [data, setData] = useState<IC0553WebsiteImage[]>([])

    useEffect(() => {
        goGetDataJson();
    }, [])

    const goGetDataJson = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0533/GetWebItemList`, {
                item_cat: "OurCrew",
                item_section_code: "RregisterOnline_",
            });
            if (res.status === 200) {
                setData(res.data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getMatchLink = (data: IC0553WebsiteImage[], SeachTitile: string) => {
        const indexID: number = data.findIndex(imageindex => imageindex.item_section_code === SeachTitile)
    
        if (indexID < 0 || indexID >= data.length) {
            return null;
        }
        
        const urlpath = data[indexID].item_link_url;
        return <a href={urlpath}> 網上報名 </a>;
    };

    const getMatchLink2 = (data: IC0553WebsiteImage[], SeachTitile: string) => {
        const indexID: number = data.findIndex(imageindex => imageindex.item_section_code === SeachTitile)
    
        if (indexID < 0 || indexID >= data.length) {
            return null;
        }
        
        const urlpath = data[indexID].item_link_url;
        return <a href={urlpath}> 章程下載 </a>;
    };

    const content = (
        <section className={css.MainSection}>
            <div className={css.PageTitleDiv}>
                <p>專業教練團隊</p>
            </div>
            <div className={css.CrewDiv1}>
                <div className={css.CrewImg1}>
                    <img src={Crew1} alt="莫利生 教練 (Calvin Mok)" />
                </div>
                <div className={css.CrewDesc}>
                    <p>莫利生 教練 (Calvin Mok)</p>
                    <p>課程總監</p>
                </div>
            </div>

            <div className={css.CrewDiv3}>
                {/* <div className={css.eachCrew}>
                    <div className={css.CrewImg3}>
                        <img src={Crew2} alt="何聲龍 教練" />
                    </div>
                    <div className={css.CrewDesc}>
                        <p>何聲龍 教練</p>
                    </div>
                </div> */}

                <div className={css.eachCrew}>
                    <div className={css.CrewImg3}>
                        <img src={Crew3} alt="馬棹謙 教練" />
                    </div>
                    <div className={css.CrewDesc}>
                        <p>馬棹謙 教練</p>
                    </div>
                </div>

                <div className={css.eachCrew}>
                    <div className={css.CrewImg3}>
                        <img src={Crew4} alt="曾㙟棕 教練" />
                    </div>
                    <div className={css.CrewDesc}>
                        <p>曾㙟棕 教練</p>
                    </div>
                </div>
            </div>

            <div className={css.CrewDiv5}>

                <div className={css.eachCrew}>
                    <div className={css.CrewImg5}>
                        <img src={Crew5} alt="Mickie 教練" />
                    </div>
                    <div className={css.CrewDesc}>
                        <p>Mickie 教練</p>
                    </div>
                </div>

                <div className={css.eachCrew}>
                    <div className={css.CrewImg5}>
                        <img src={Crew6} alt="尹敏慧 教練" />
                    </div>
                    <div className={css.CrewDesc}>
                        <p>尹敏慧 教練</p>
                    </div>
                </div>

                <div className={css.eachCrew}>
                    <div className={css.CrewImg5}>
                        <img src={Crew7} alt="盧倩珩 教練" />
                    </div>
                    <div className={css.CrewDesc}>
                        <p>盧倩珩 教練</p>
                    </div>
                </div>

                <div className={css.eachCrew}>
                    <div className={css.CrewImg5}>
                        <img src={Crew8} alt="蘇家盈 教練" />
                    </div>
                    <div className={css.CrewDesc}>
                        <p>蘇家盈 教練</p>
                    </div>
                </div>

                <div className={css.eachCrew}>
                    <div className={css.CrewImg5}>
                        <img src={Crew9} alt="羅燊楣 教練" />
                    </div>
                    <div className={css.CrewDesc}>
                        <p>羅燊楣 教練</p>
                    </div>
                </div>
            </div>

            <div className={css.TeamImageDiv}>
                <img src={CrewTeam} alt="Crew Team" />
            </div>

            <div className={css.TeamDescDiv}>
                <p>我們的教練除了有豐富的跳繩經驗外，更具備專業的教練資格。</p>
                <p>（全部皆考獲由「中國香港跳繩總會」認可之教練班）</p>
                <p>本社更為教練們定期提供工作坊，讓他們在教學上更緊貼繩界發展。</p>
            </div>

            <div className={css.Cours}>
                <div className={css.SkillCours}>
                    <div className={css.CoursTitle}>
                        <p>跳繩技術班： {getMatchLink(data, "RregisterOnline_01")}</p>
                    </div>
                    <div className={css.CourDesc}>
                        {getMatchLink2(data, "RregisterOnline_03")}
                    </div>
                </div>
                <div className={css.BaseCours}>
                    <div className={css.CoursTitle}>
                        <p>初級教練班:  {getMatchLink(data, "RregisterOnline_02")}</p>
                    </div>
                    <div className={css.CourDesc}>
                        {getMatchLink2(data, "RregisterOnline_04")}
                    </div>
                </div>
            </div>

            <div className={css.DescMessage}>
                <p>HKRSU 新一期跳繩技術班 ， 初級跳繩教練班 現正接受報名！ 👏🏻</p>
                <p>大家如果身邊有朋友有興趣，可以報名參加！🏃🏻🏃🏻‍♀️🏃🏻‍♂️</p>
                <p>#需要先完成技術班才可以報教練班</p>
                <p>#技術班年滿16歲便可參加，而教練班則需要18歲</p>
                <p>跳繩技術班</p>
            </div>

        </section>
    )
    return content
}
export default OurCrew