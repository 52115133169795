import Slider from "react-slick"

import css from '../../css/C0533/HomePageImageSilde.module.css'
import { useEffect, useState } from "react";
import axios from "axios";
import { IC0553WebsiteImage } from "../../Interface/interface";
import { NextArrow, PrevArrow } from './CustomArrows'; // Adjust the path as necessary


const ImageSlider = () => {

  const [slideImg, setSlideImg] = useState<IC0553WebsiteImage[]>([])

  useEffect(() => {
    loadSlideImage();
  }, [])

  const loadSlideImage = async () => {
    try {

      console.log(`Backend : ${process.env.REACT_APP_API_URL}` )

      const res = await axios.post(`${process.env.REACT_APP_API_URL}/c0533/GetWebItemList`, {
        item_cat: "home",
        item_section_code: "slide_"
      });

      if (res.status === 200) {
        console.log(res.data)
        setSlideImg(res.data)
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  var settings = {
    dots: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  const content = (
    <section className={css.MainSection}>
      <div className={css.ImageSliderBody}>
        <Slider {...settings}>
          {slideImg.map((data) => (
            <div className={css.ImageSliderImg}>
              <img src={data.item_pic_url} alt={data.item_desc} />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
  return content

}
export default ImageSlider